<template>
    <Modal ref="accountModal" :value="show" width="30%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onvisit">
        <p slot="header" style="color:#f60;text-align:left">
            <span v-if="userid>=0">认证账号修改</span>
            <span v-else>认证账号添加</span>
        </p>
        <Form ref="SubAccountForm" :model="formInline" :label-width="120" label-position="right" :rules="ruleInline">
            <Row>                
              <i-col span="24">
                <FormItem  prop="username" label="账号名称:">
                    <i-input v-if="userid>=0" type="text" v-bind:value="username"  :disabled=true  :maxlength="30" show-word-limit ></i-input>
                    <i-input v-else type="text" v-model="formInline.username"  :maxlength="30" show-word-limit :autofocus=true></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="password" label="账号密码">
                    <i-input type="password" v-model="formInline.password" password></i-input>
                </FormItem>                  
              </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                  <FormItem prop="fullname" label="认证机构">
                      <i-input type="text" v-model="formInline.org" :maxlength="20" show-word-limit></i-input>
                  </FormItem>                  
                </i-col>
              </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('SubAccountForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'AccountEdit',
        props:['show','userid','username','org'],
        data () {
            return {
                formInline: {
                    username: this.username,
                    password:'',
                    type:0,
                    org:this.org,
                    id:null
                },
                ruleInline: {
                    username: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { pattern: /^[a-zA-Z][a-zA-Z0-9]{4,15}$/, message: '需以字母开头的4-16位英文字母和数字组合', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
                    ],
                }
            }
        },
        mounted (){
            this.$axios({
                method:'post',
                url:"/api/ycuser/department/query",
                data:{companyid:this.companyid},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.departmentList = res.data.data
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));            
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid) =>{
                    if(valid){
                        var posturl=''
                        var postdata=null
                        if(this.userid >=0){
                            posturl = "/api/ycuser/users/update"
                            postdata ={
                                id:this.userid,
                                password:this.formInline.password,
                                org:this.formInline.org
                            }                            
                        }
                        else{
                            posturl ="/api/ycuser/users/addnew"
                            postdata=this.formInline
                        }
                        this.$axios({
                            method:'post',
                            url:posturl,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success({content:res.data.msg, duration:3})
                                if(this.userid >=0){
                                    this.formInline.password=''
                                    this.formInline.org = ''
                                    this.$refs['accountModal'].close()
                                }else{
                                    this.$refs['SubAccountForm'].resetFields()
                                }
                            }
                            else{
                                this.$Message.error({content:res.data.msg, duration:3})
                            }
                        }.bind(this));   
                    }
                    else{
                        this.$Message.error("请输入正确的内容")
                    }
                })
            },
            oncancel(){
                this.$emit('CloseAccountEdit','')
            },
            onvisit(visit){
                if(visit){
                    this.formInline.username = this.username
                    this.formInline.id = this.userid
                    this.formInline.org = this.org
                }
            }
        }
    }
</script>
