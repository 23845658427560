<template>
<div >
    <div style="height:40px;width:100%;">
        <h2>验厂人员账号管理</h2>
    </div>
    
    <!-- 部门账号列表-->
    <div>
        <div style="height:40px;width:100%;">
            <Button type="warning" icon="ios-add-circle-outline" style="margin-top:6px;float:left" @click="addSubAccount">添加账号</Button>
        </div>        
        <Table border :columns="tblcolumns" :data="tbldata">
        <template slot-scope="{ row }" slot="deptname">
            <strong>{{ row.deptname }}</strong>
        </template>
        <template slot-scope="{ row, index }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="modify(index)">修改</Button>
            <Button type="error" size="small" @click="remove(index)">删除</Button>
        </template>
        </Table>
        <accountedit v-bind:show="showAccountEdit" v-bind:userid="editUserId"  v-bind:username="editUserName" v-bind:org="editOrg"  @CloseAccountEdit="onAccountEditClose"/>
        <Modal v-model="showDeleteDialog" title="确定要删除吗?" @on-ok="confirmDelete">
          <p>删除账号{{editUserName}}</p>
        </Modal>
    </div>

    </div>
</template>

<script>
import AccountEdit from './AccountEdit'
export default {
    name:'Account',
    data () {
        return {
            showAccountEdit:false,
            editAccountId: this._self.$root.$store.state.LogonInfo.companyid,
            editUserId:null,
            editUserName:null,
            editOrg:null,
            showDeleteDialog:false,
            companyname:this._self.$root.$store.state.LogonInfo.companyname,
            tblpageinfo:{
                total:0,
                currentpage:1,
                pagesize:20
            },
            tblcolumns: [
                {
                    title: '账号',
                    key: 'username',         
                },
                {
                    title: '开通日期',
                    key: 'createtime'
                },
                {
                    title: '认证机构',
                    key: 'org'
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center'
                }
                
            ],
            tbldata: []
        }
    },
    mounted (){
        this.refreshycusers()
    },
    methods: {
        addSubAccount(){
            this.editUserId = -1
            this.showAccountEdit = true
            this.editUserName =''
            this.editUserDeptcode =''
            this.editUserFullname = ''
        },
        modify (index) {
            this.editUserId = this.tbldata[index].id
            this.editUserName =this.tbldata[index].username
            this.editOrg =this.tbldata[index].org

            this.showAccountEdit = true
        },
        remove (index) {
            //this.tbldata.splice(index, 1);
            this.editUserId = this.tbldata[index].id
            this.editUserName =this.tbldata[index].username
            this.showDeleteDialog = true
        },
        confirmDelete(){
            this.$axios({
                method:'post',
                url:"/api/ycuser/users/delete",
                data:{
                    userid:this.editUserId,
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Message.success({content:res.data.msg, duration:3})
                    this.refreshycusers()
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));               
        },
        onAccountEditClose (){
            this.showAccountEdit = false
            this.refreshycusers()
        },
        refreshycusers(){
            console.log(this.editAccountId)
            this.$axios({
                method:'post',
                url:"/api/ycuser/users/query",
                data:null,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                console.log(res.data)
                if(res.data.resultCode ==0){
                    this.tbldata = res.data.data
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));   
        },
    },
    components:{
        'accountedit': AccountEdit
    },
    
}
</script>